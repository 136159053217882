import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, take } from 'rxjs/operators';

import { Account } from '@libs/src/models/account.model';
import { IAppState } from '@main-client/src/app/app.state';
import { isTenThousandCoffeesAdmin } from '@main-client/src/app/user/user.helpers';

import isEmpty from 'lodash-es/isEmpty';

@Injectable()
export class TenThousandCoffeesAdminGuard implements CanActivate {
  constructor(private router: Router, private store: Store<IAppState>) {}

  canActivate(route: ActivatedRouteSnapshot) {
    return this.store.select('user').pipe(
      filter((user) => !isEmpty(user)),
      take(1),
      map((user: Account) => {
        return (
          isTenThousandCoffeesAdmin(user) ||
          this.router.createUrlTree(['/home'])
        );
      }),
    );
  }
}
