import { Action } from '@ngrx/store';

export const GET_HUBS_TYPE = '[hubs] Get';
export const GET_HUBS_SUCCESS_TYPE = '[hubs] Get Success';
export const SET_HUBS_FILTERS_TYPE = '[hubs] Set Filters';
export const REMOVE_HUBS_FILTER_TYPE = '[hubs] Remove Filter';
export const RESET_HUBS_TYPE = '[hubs] Reset';
export const SET_HUBS_KEYWORD_TYPE = '[hubs] Set Keyword';
export const GET_ALL_HUBS_TYPE = '[hubs] Get All';

export class HubsActions implements Action {
  constructor(public type: string = '', public payload: any = {}) {}
}

export function GetHubs(payload: any) {
  return new HubsActions(GET_HUBS_TYPE, payload);
}

export function GetHubsSuccess(payload: any) {
  return new HubsActions(GET_HUBS_SUCCESS_TYPE, payload);
}

export function SetFilters(payload: any) {
  return new HubsActions(SET_HUBS_FILTERS_TYPE, payload);
}

export function RemoveFilter(payload: any) {
  return new HubsActions(REMOVE_HUBS_FILTER_TYPE, payload);
}

export function Reset() {
  return new HubsActions(RESET_HUBS_TYPE);
}

export function SetHubsKeyword(payload: any) {
  return new HubsActions(SET_HUBS_KEYWORD_TYPE, payload);
}

export function GetAllHubs(payload: any) {
  return new HubsActions(GET_ALL_HUBS_TYPE, payload);
}
