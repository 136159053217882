import { ActivatedRouteSnapshot } from '@angular/router';

import {
  Account,
  AccountGroupMembership,
} from '@libs/src/models/account.model';
import {
  Group,
  GroupCustomSegment,
  GroupHiddenCustomSegment,
} from '@libs/src/models/group.model';
import { getMembership } from '@main-client/src/app/account/account.helpers';
import {
  NotificationCustomRecipients,
  NotificationRecipients,
  Opportunity,
} from '@main-client/src/app/opportunity/opportunity.model';

import isEmpty from 'lodash-es/isEmpty';
import isUndefined from 'lodash-es/isUndefined';

export const EVENTS_ROUTE = 'events';
export const EVENT_ROUTE = '/event';
export const EVENTS_PATHS = {
  create: 'create',
  event: 'event',
  events: 'events',
};

export function checkIsUserInvitedByProfileAttributes(
  group: Group,
  invitationRecipients: NotificationRecipients,
  user: Account,
): boolean {
  const isUserInvitedByEmail = invitationRecipients.emails?.includes(
    user.email,
  );
  if (isUserInvitedByEmail) {
    return isUserInvitedByEmail;
  }
  const groupMembership = getMembership(user.groups, group._id);
  const roleAndCustomSegmentSelections =
    !isEmpty(invitationRecipients.segment_groups) &&
    !isUndefined(invitationRecipients.segment_groups)
      ? invitationRecipients.segment_groups[0]
      : {
          custom_segment_nodes: invitationRecipients.custom_segment_nodes,
          hidden_custom_segment_nodes: [],
          roles: invitationRecipients.roles,
        };
  if (
    roleAndCustomSegmentSelections.roles?.length ||
    roleAndCustomSegmentSelections.custom_segment_nodes?.length ||
    roleAndCustomSegmentSelections.hidden_custom_segment_nodes?.length
  ) {
    const userRoleId = getRoleFromAccountMembership(groupMembership, group);
    const hiddenCustomSegments = group.hidden_custom_segments || [];
    return checkInvitationProfileAttributes(
      group.custom_segments,
      hiddenCustomSegments,
      groupMembership,
      roleAndCustomSegmentSelections,
      userRoleId,
    );
  }
  return false;
}

function getRoleFromAccountMembership(
  accountGroupMembership: AccountGroupMembership,
  group: Group,
) {
  const groupRole = group.roles?.find(
    (role) => accountGroupMembership.role === role.name,
  );
  return groupRole?._id;
}

function checkInvitationProfileAttributes(
  groupCustomSegments: GroupCustomSegment[],
  groupHiddenCustomSegments: GroupHiddenCustomSegment[] | [],
  groupMembership: AccountGroupMembership,
  invitationRecipients: NotificationCustomRecipients,
  userRoleId: string,
): boolean {
  if (
    !isEmpty(invitationRecipients.roles) &&
    !invitationRecipients.roles.includes(userRoleId)
  ) {
    return false;
  }
  const userHasSelectedCustomSegments = checkCustomSegments(
    groupCustomSegments,
    'segment_selections',
    groupMembership,
    invitationRecipients.custom_segment_nodes,
  );
  if (isEmpty(invitationRecipients.hidden_custom_segment_nodes)) {
    return userHasSelectedCustomSegments;
  }
  const userHasSelectedHiddenCustomSegments = checkCustomSegments(
    groupHiddenCustomSegments,
    'hidden_segment_selections',
    groupMembership,
    invitationRecipients.hidden_custom_segment_nodes,
  );
  return userHasSelectedCustomSegments && userHasSelectedHiddenCustomSegments;
}

function checkCustomSegments(
  customSegments: (GroupCustomSegment | GroupHiddenCustomSegment)[],
  segmentSelectionType: string,
  groupMembership: AccountGroupMembership,
  invitationRecipientNodes: string[],
) {
  return customSegments?.reduce((result, customSegment) => {
    const selections = invitationRecipientNodes.reduce(
      (selectionResult, selectedNode) => {
        const nodeSelected = customSegment.nodes.find(
          (node) => selectedNode === node._id,
        );
        if (nodeSelected) {
          selectionResult.push(nodeSelected.name);
          return selectionResult;
        }
        return selectionResult;
      },
      [],
    );
    if (isEmpty(selections)) {
      return result;
    }
    const userSegmentSelection = (groupMembership as any)[
      segmentSelectionType
    ]?.find((segment: any) => customSegment._id === segment.custom_segment);
    if ('multi' !== customSegment.selection_type) {
      return (
        !!userSegmentSelection &&
        selections.includes(userSegmentSelection?.node) &&
        result
      );
    }
    return (
      !!userSegmentSelection &&
      userSegmentSelection?.multiselected_nodes?.some((node: any) =>
        selections.includes(node),
      ) &&
      result
    );
  }, true);
}

export function didUserSignupThroughEvent(
  eventId: string,
  publicUrl: string,
  slug: string,
  user: Account,
) {
  return (
    !!publicUrl &&
    'office_hours' === user.signup_method &&
    eventId.concat('-', slug) === user.signup_method_details
  );
}

export function isVisitingViaRegistrationLink(
  event: Opportunity,
  route: ActivatedRouteSnapshot,
) {
  return event.public_url && event._id === route?.queryParamMap?.get('eventId');
}
