import { Action } from '@ngrx/store';

import { Group } from '@libs/src/models/group.model';
import { Match } from '@libs/src/models/match.interfaces';

export const GET_MATCHES_TYPE = '[matches] Get';
export const GET_MATCHES_SUCCESS_TYPE = '[matches] Get Success';
export const GET_MATCH_TYPE = '[match] Get';
export const GET_MATCH_SUCCESS_TYPE = '[match] Get Success';
export const GET_MATCH_ERROR_TYPE = '[match] Get Error';
export const FIND_MATCH_FOR_GROUP_TYPE = '[match] Find For Group';
export const UPDATE_MATCH_STATUS_TYPE = '[match] Update Status';
export const UPDATE_MATCH_STATUS_SUCCESS_TYPE = '[match] Update Status Success';

export class MatchesActions implements Action {
  constructor(public type: string = '', public payload: any = {}) {}
}

export function FindMatchForGroup(payload: Group) {
  return new MatchesActions(FIND_MATCH_FOR_GROUP_TYPE, payload);
}

export function GetMatch(payload: { matchId: string; group: Group }) {
  return new MatchesActions(GET_MATCH_TYPE, payload);
}

export function GetMatchSuccess(payload: Match) {
  return new MatchesActions(GET_MATCH_SUCCESS_TYPE, payload);
}

export function GetMatchError(payload: any) {
  return new MatchesActions(GET_MATCH_ERROR_TYPE, payload);
}

export function GetMatches() {
  return new MatchesActions(GET_MATCHES_TYPE);
}

export function GetMatchesSuccess(payload: Match[]) {
  return new MatchesActions(GET_MATCHES_SUCCESS_TYPE, payload);
}

export function UpdateMatchStatus(payload: {
  match: Match;
  status: string;
  userId: string;
}) {
  return new MatchesActions(UPDATE_MATCH_STATUS_TYPE, payload);
}

export function UpdateMatchStatusSuccess(payload: Match) {
  return new MatchesActions(UPDATE_MATCH_STATUS_SUCCESS_TYPE, payload);
}
