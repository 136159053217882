import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, take } from 'rxjs/operators';

import { Account } from '@libs/src/models/account.model';

import { IAppState } from '../app.state';

import isEmpty from 'lodash-es/isEmpty';

@Injectable({ providedIn: 'root' })
export class TenantAdminGuardService implements CanActivate {
  constructor(public router: Router, private store: Store<IAppState>) {}

  canActivate() {
    return this.store.select('user').pipe(
      filter((user: any) => !isEmpty(user)),
      take(1),
      map((user: Account) => {
        if (user.isAdmin) {
          return true;
        }
        return this.router.createUrlTree(['/']);
      }),
    );
  }
}
