import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import fr from '@angular/common/locales/fr';
import ja from '@angular/common/locales/ja';
import ko from '@angular/common/locales/ko';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, TitleStrategy } from '@angular/router';
import {
  DeleteOutline,
  EditOutline,
  EllipsisOutline,
  LockOutline,
  ReloadOutline,
  SettingOutline,
  SyncOutline,
  UnlockOutline,
} from '@ant-design/icons-angular/icons';
import { JwtModule } from '@auth0/angular-jwt';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ApolloModule } from 'apollo-angular';
import { en_US, es_ES, fr_FR, ja_JP, ko_KR, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { CookieService } from 'ngx-cookie-service';
import 'reflect-metadata';

import { CoreModule } from './core/core.module';
import { HeaderModule } from './header/header.module';
import { UserModule } from './user/user.module';
import { AvatarModule } from '@libs/src/avatar/avatar.module';
import { GraphQLModule } from '@libs/src/graphql/graphql.module';
import { RouteLoadingIndicatorModule } from '@libs/src/route-loading-indictator/route-loading-indicator.module';
import { HubsStoreModule } from '@main-client/src/app/hubs/hubs-store.module';
import { UserIdleTimeoutModule } from '@main-client/src/app/user-idle-timeout/user-idle-timeout.module';
import { UserStoreModule } from '@main-client/src/app/user/user-store.module';

import { AUTH_TOKEN_KEY, TENANT_TOKEN_KEY } from './core/local-storage.service';
import { TenantMutationService } from './tenant/tenant-mutation.service';
import { TenantService } from './tenant/tenant.service';
import { GlobalErrorHandler } from '@libs/src/global-error-handler/global-error-handler.service';
import { CanLoadConversationsModuleGuardService } from '@main-client/src/app/core/can-load-conversations-module-guard.service';
import { TenThousandCoffeesAdminGuard } from '@main-client/src/app/core/ten-thousand-coffees-admin-guard.service';

import { EmptyComponent } from './empty/empty.component';
import { RootComponent } from './root/root.component';

import { appEffects } from './app.effects';
import { appReducers } from './app.reducers';
import { appRoutes } from './app.routes';
import { TemplatePageTitleStrategy } from './core/template-page-title-strategy';
import { LocaleMap } from '@libs/src/locale/locale-map.interface';
import { ActiveUserGuard } from '@main-client/src/app/core/active-user.guard';

registerLocaleData(fr);
registerLocaleData(ko);
registerLocaleData(ja);

export function tokenGetter() {
  if (localStorage.getItem(TENANT_TOKEN_KEY)) {
    return localStorage.getItem(AUTH_TOKEN_KEY);
  }
  return null;
}

@NgModule({
  bootstrap: [RootComponent],
  declarations: [EmptyComponent, RootComponent],
  imports: [
    ApolloModule,
    AvatarModule,
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule,
    FlexLayoutModule,
    GraphQLModule,
    HeaderModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: { tokenGetter },
    }),
    HubsStoreModule,
    MatButtonModule,
    RouterModule.forRoot(appRoutes),
    StoreModule.forRoot(appReducers),
    EffectsModule.forRoot(appEffects),
    NzIconModule.forRoot([
      EditOutline,
      EllipsisOutline,
      DeleteOutline,
      LockOutline,
      ReloadOutline,
      SettingOutline,
      SyncOutline,
      UnlockOutline,
    ]),
    MatListModule,
    StoreDevtoolsModule.instrument({ maxAge: 25 }),
    MatMenuModule,
    MatSidenavModule,
    RouteLoadingIndicatorModule,
    UserIdleTimeoutModule,
    UserModule,
    UserStoreModule,
  ],
  providers: [
    ActiveUserGuard,
    CanLoadConversationsModuleGuardService,
    CookieService,
    TenantMutationService,
    TenantService,
    TenThousandCoffeesAdminGuard,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      deps: [LOCALE_ID],
      provide: NZ_I18N,
      useFactory: (localeId: string) => {
        const localeMap: LocaleMap = {
          'en-US': en_US,
          es: es_ES,
          'fr-CA': fr_FR,
          ja: ja_JP,
          ko: ko_KR,
        };
        return (localeMap as any)[localeId];
      },
    },
    {
      provide: TitleStrategy,
      useClass: TemplatePageTitleStrategy,
    },
  ],
})
export class AppModule {}
