<div
  fxLayout="row"
  fxLayoutAlign="center center"
  [ngStyle]="{ width: size + 'px', height: size + 'px' }"
>
  <img
    *ngIf="imageUrl"
    [ngStyle]="{ 'border-radius': '50%' }"
    [src]="imageUrl"
    [width]="size"
    [height]="size"
    alt=""
  />
  <ng-container *ngIf="!imageUrl">
    <p
      *ngIf="!showCamera"
      fxLayout="row"
      fxLayoutAlign="center center"
      [ngStyle]="{ width: '100%', height: '100%', 'border-radius': '50%' }"
      [ngClass]="{
        'text-h4': AVATAR_SIZES.xlarge === size,
        'text-subtitle-1': AVATAR_SIZES.large === size
      }"
      class="background-color-secondary u-userSelect-none color-text-default"
    >
      {{ name && name[0] }}
    </p>
    <div
      *ngIf="showCamera"
      fxLayout="row"
      fxLayoutAlign="center center"
      [ngStyle]="{ width: '100%', height: '100%', 'border-radius': '50%' }"
      class="bg-color-teal-100"
    >
      <i class="material-icons md-48">photo_camera</i>
    </div>
  </ng-container>
</div>
