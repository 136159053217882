import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { GroupsBackend } from '@libs/src/groups/groups.backend';

import { Group } from '@libs/src/models/group.model';

@Injectable({
  providedIn: 'root',
})
export class GroupMembershipSettingsGuard implements CanActivate {
  defaultFallback = this.router.createUrlTree(['/home']);

  constructor(public groupsBackend: GroupsBackend, public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const groupId = route.queryParamMap.get('groupId');
    if (!groupId) {
      return of(this.defaultFallback);
    }
    return this.groupsBackend.getGroup({ _id: groupId }).pipe(
      map((group: Group) =>
        this.router.createUrlTree(['/hub', group.slug, 'member-settings']),
      ),
      catchError(() => of(this.defaultFallback)),
    );
  }
}
