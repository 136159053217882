import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, timer } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import { IAppState } from '../app.state';
import {
  SetUnseenConversationsCount,
  SetUnseenNotificationsCount,
} from '../header/header.actions';

import includes from 'lodash-es/includes';

const CONVERSATIONS_API_URL = '/api/v2/account/conversations';
const NOTIFICATIONS_API_URL = '/api/v2/notifications';
const POLLING_TIME_IN_MS = 25000;
const UPDATES_API_URL = '/api/v2/account/updates';

interface IUpdate {
  name: string;
  getter: Observable<any>;
  storeAction: any;
}

@Injectable()
export class UpdatesService {
  updates: IUpdate[];

  constructor(private http: HttpClient, private store: Store<IAppState>) {
    this.updates = [
      {
        name: 'conversations',
        getter: this.getUnseenConversations(),
        storeAction: SetUnseenConversationsCount,
      },
      {
        name: 'notifications',
        getter: this.getUnseenNotifications(),
        storeAction: SetUnseenNotificationsCount,
      },
    ];
  }

  init() {
    timer(0, POLLING_TIME_IN_MS)
      .pipe(
        concatMap(() => {
          const time = new Date();
          time.setMilliseconds(time.getMilliseconds() - POLLING_TIME_IN_MS);
          const timestamp = time.toISOString();
          const params = new HttpParams().set('timestamp', timestamp);
          return this.http.get(UPDATES_API_URL, { params });
        }),
      )
      .subscribe((componentsWithUpdates: any) => {
        this.updates
          .filter((update) => includes(componentsWithUpdates, update.name))
          .forEach((update) =>
            this.invokeUpdate(update.getter, update.storeAction),
          );
      });

    this.updates.forEach((update) => {
      this.invokeUpdate(update.getter, update.storeAction);
    });
  }

  invokeUpdate(getter: Observable<any>, storeAction: any) {
    getter.subscribe((value: any) => {
      this.store.dispatch(storeAction(value.total_count));
    });
  }

  getUnseenConversations() {
    const params = new HttpParams().set('filters', ['unread'].toString());
    return this.http.get(CONVERSATIONS_API_URL, { params });
  }

  getUnseenNotifications() {
    const params = new HttpParams().set('seen', false.toString());
    return this.http.get(NOTIFICATIONS_API_URL, { params });
  }
}
