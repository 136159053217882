import { Action } from '@ngrx/store';

export const TENANT_SET_TYPE = '[tenant] Set';
export const TENANT_PATCH_TYPE = '[tenant] Patch';

export class TenantAction implements Action {
  constructor(public type: string = '', public payload: any = {}) {}
}

export function SetTenant(payload: any): TenantAction {
  return new TenantAction(TENANT_SET_TYPE, payload);
}

export function PatchTenant(payload: any): TenantAction {
  return new TenantAction(TENANT_PATCH_TYPE, payload);
}
