import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, take } from 'rxjs/operators';

import { IAppState } from '@main-client/src/app/app.state';

import isEmpty from 'lodash-es/isEmpty';

@Injectable()
export class HasGroupsGuard implements CanActivate {
  constructor(private store: Store<IAppState>, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.select('user').pipe(
      filter((user: any) => !isEmpty(user)),
      take(1),
      map((user: any) => {
        const hasGroups = !!user.groups.length;
        if (!hasGroups) {
          this.router.navigate(['/hubs']);
        }
        return hasGroups;
      }),
    );
  }
}
