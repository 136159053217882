import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

import { ToastModule } from '@libs/src/toast/toast.module';
import { TtcButtonSharedModule } from '@libs/src/ttc-button-shared/ttc-button-shared.module';
import { TranslateModule } from '@main-client/src/app/translate/translate.module';

import { NzModalModule } from 'ng-zorro-antd/modal';
import { LocaleManagerComponent } from './locale-manager.component';

@NgModule({
  declarations: [LocaleManagerComponent],
  exports: [LocaleManagerComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatSelectModule,
    NzModalModule,
    ReactiveFormsModule,
    ToastModule,
    TranslateModule,
    TtcButtonSharedModule,
  ],
})
export class LocaleManagerModule {}
