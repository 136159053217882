import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client/core';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import { createPersistedQueryManifestVerificationLink } from '@apollo/persisted-query-lists';
import type { PersistedQueryManifestForVerification } from '@apollo/persisted-query-lists';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

import { environment } from '../environments/environment';

export const graphqlApiUrl = '/api/graphql';
export function createApollo(
  httpLink: HttpLink,
): ApolloClientOptions<NormalizedCacheObject> {
  const cache = new InMemoryCache();
  const chain: ApolloLink[] = [
    removeTypenameFromVariables(),
    httpLink.create({
      includeQuery: false,
      uri: graphqlApiUrl,
    }),
  ];
  if (!environment.production) {
    const verificationLink = createPersistedQueryManifestVerificationLink({
      loadManifest: async () =>
        (await import(
          './persisted-query-manifest.json'
        )) as PersistedQueryManifestForVerification,
      onVerificationFailed(details) {
        console.warn(
          `⚠️ Non-persisted graphql request ${details.operation.operationName} (${details.reason})`,
        );
        if (
          'OperationMismatch' === details.reason ||
          'UnknownOperation' === details.reason
        ) {
          details.operation.setContext({ includeQuery: true });
        }
      },
    });
    chain.unshift(verificationLink);
  }
  const link = ApolloLink.from(chain);
  return { cache, link, connectToDevTools: !environment.production };
}

@NgModule({
  exports: [ApolloModule],
  imports: [ApolloModule, HttpClientModule],
  providers: [
    {
      deps: [HttpLink],
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
    },
  ],
})
export class GraphQLModule {}
